import React from "react";
import {graphql} from "gatsby";
import CategoryLayout from "../../components/tronpedia/category-layout";
import {Helmet} from "react-helmet";
import cryptoBull from "../../images/tronpedia/topic-pages/crypto-bull.png";

const Crypto = ({data, location}) => {

    const pageData = {
        title: "Crypto",
        description: "Here, we break down what cryptocurrencies are, how they’ve worked at such a massive scale, and the biggest names in the space.",
        img: cryptoBull,
    }


    return (
        <>
            <Helmet>
                <title>TRONpedia Crypto | TRONDAO</title>
                <meta name="description"
                      content="Looking to get into crypto? This section is for you! Learn the basics of cryptography, some of its most popular applications, and so much more."/>
                <meta property="og:description"
                      content="Looking to get into crypto? This section is for you! Learn the basics of cryptography, some of its most popular applications, and so much more."/>
                <meta property="og:title" content="TRONpedia Crypto | TRONDAO"/>
            </Helmet>
            <CategoryLayout data={data} location={location} pageData={pageData}/>
        </>
    );
};


export default Crypto;

export const pageQuery = graphql`
query {
   allWpTronpediaArticle (
    filter: {tronpedia_article_info: {category: {eq: "CRYPTO"}}}
  ) {  
      edges {
         node {
            slug
        title
        tronpedia_article_info {
           category
          featured
          seoDescription
          seoTitle
          shortDescription
          image {
                  sourceUrl
               }
            }
         }
      }
   }
}

`
